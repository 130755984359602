import { ArrowRight } from "@carbon/icons-react";

interface CardProps {
  title?: string;
  imageSrc?: any;
  onClick?: () => void;
}

export default function Card({ title, imageSrc, onClick }: CardProps) {
  return (
    <div
      className="flex flex-col items-center w-[208px] h-[246px] bg-peras-blue-200 cursor-pointer shadow-lg"
      onClick={onClick}
    >
      <div className="m-4">
        <p className="font-bold text-center text-sm w-36">{title}</p>
      </div>

      <div className="flex justify-center items-center h-28">
        <img src={imageSrc} alt="" />
      </div>

      <div className="flex flex-row items-center">
        <p className="text-primary text-sm w-full text-center">Weiter</p>
        <ArrowRight size={20} className="text-primary ml-1" />
      </div>
    </div>
  );
}
