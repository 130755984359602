import React, { useState } from "react";
import { useUser } from "../../atoms/useUser";
import { TextInput, Link, Button } from "@carbon/react";
import { ArrowRight } from "@carbon/icons-react";
import { useNavigate } from "react-router-dom";
import { setUserIdCookie } from "../../utils/setUserIdCookie";

export default function LoginPage() {
  const navigate = useNavigate();
  const [userState, setUserState] = useState("");
  const [pw, setPw] = useState("");
  const [showPwInvalid, setShowPwInvalid] = useState(false);
  const [user, setUser] = useUser();

  const handleChange = (event: any) => {
    setUserState(event.target.value);
  };

  const login = () => {
    if (userState.toLowerCase() === "barbara") {
      if (pw === "perashoop") {
        setShowPwInvalid(false);
        setUser("Barbara");
        setUserIdCookie("Barbara");
        navigate("/admin/processes");
      } else {
        setShowPwInvalid(true);
      }
    } else if (userState.toLowerCase() === "arndt1") {
      if (pw === "perashoop") {
        setUser("Beste Bank");
        setUserIdCookie("Beste Bank");
        navigate("/processes");
      } else {
        setShowPwInvalid(true);
      }
    } else {
      if (pw === "perashoop") {
        setUser("Allerbeste Bank");
        setUserIdCookie("Allerbeste Bank");
        navigate("/processes");
      } else {
        setShowPwInvalid(true);
      }
    }
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      login();
    }
  };

  return (
    <div className="pl-24 h-full bg-[url('../assets/background-transparent.png')] bg-no-repeat bg-right bg-[auto_100%] flex items-center">
      <div className="w-[400px] pb-40">
        <h4 className="text-3xl">Willkommen im</h4>
        <h4 className="text-3xl pb-4">Peras Onboarding Service</h4>
        <p className="text-sm">Bitte melden Sie sich an, um wichtige Informationen</p>
        <p className="text-sm pb-11">für das Onboarding Ihres Mitarbeitenden anzugeben.</p>
        <TextInput
          className="pb-7"
          id="email-input"
          type="text"
          onKeyPress={onKeyPress}
          labelText="E-Mail"
          placeholder="example@email.com"
          onChange={handleChange}
        />
        <TextInput
          type="password"
          value={pw}
          onChange={(e) => setPw(e.target.value)}
          id="password-input"
          onKeyPress={onKeyPress}
          invalid={showPwInvalid}
          invalidText="Benutzername und Passwort stimmen nicht überein."
          labelText="Passwort"
          placeholder="Passwort"
        />
        <Link className="pb-7 text-primary">Passwort vergessen?</Link>
        <br />
        <Button className="login-window bg-primary hover:bg-peras-blue-400" renderIcon={ArrowRight} onClick={login}>
          Anmelden
        </Button>
      </div>
    </div>
  );
}
