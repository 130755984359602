import {
  ChatLaunch,
  CheckmarkOutline,
  CloseOutline,
  DocumentDownload,
  PlayFilledAlt,
} from "@carbon/icons-react";
import {
  ContentSwitcher,
  Switch,
  IconButton,
  Breadcrumb,
  BreadcrumbItem,
  SkeletonText,
  InlineNotification,
  Button,
  Modal as CarbonModal,
  Toggle,
} from "@carbon/react";
import { Save } from "@carbon/react/icons";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { postEmployeeData } from "../../api/postEmployeeData";
import { putEmployeeData } from "../../api/putEmployeeData";
import { useFormState } from "../../atoms/useFormState";
import { useUser } from "../../atoms/useUser";
import {
  showInvalidFieldsSaveAtom,
  useShowInvalidFieldsSave,
} from "../../atoms/useShowInvalidFieldsSave";
import { useEmployeeData } from "../../hooks/useEmployeeData";
import OrganisationalInformation from "./OrganisationalInformation";
import PersonalInformation from "./PersonalInformation";
import Chat from "../../components/Chat/Chat";
import { downloadDocument } from "../../api/downloadDocument";
import NotificationBubble from "../../components/Chat/NotificationBubble";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt";
import WarningMessage from "../../components/WarningMessage/WarningMessage";
import * as _ from "lodash";
import Modal from "../../components/Modal/Modal";
import { addQueueItem } from "../../api/addQueueItem";
import { validToSaveData, validToStartBot } from "../../utils/validator";
import { useShowInvalidFieldsStartBot } from "../../atoms/useShowInvalidFieldsStartBot";
import BotVideo from "../../components/VideoPlayer/BotVideo";
import { deleteEmployee } from "../../api/deleteEmployee";

type NotificationType = "error" | "success" | "info" | "warning";

export default function MasterDataInputPage() {
  const [formState] = useFormState();
  const [user] = useUser();
  const [section, setSection] = useState("personalInformation");
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [showChat, setShowChat] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [notificationText, setNotificationText] = useState("");
  const [notificationType, setNotificationType] = useState<NotificationType>("info");
  const [showUnsavedChangesWarning, setShowUnsavedChangesWarning] = useState<boolean>(false);
  const [botEnvironmentAvailable, setBotEnvironmentAvailable] = useState<boolean>(false);
  const [, setShowInvalidFieldsSave] = useShowInvalidFieldsSave();
  const [, setShowInvalidFieldsStartBot] = useShowInvalidFieldsStartBot();

  const { id } = useParams();
  const { isLoading, employeeData } = useEmployeeData(id);
  const [showBotVideo, setShowBotVideo] = useState<boolean>(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showUnsavedChangesWarning);

  const userIsAdmin = user === "Barbara";
  const routePrefix = userIsAdmin ? "/admin" : "";
  const navigate = useNavigate();
  const basicPersonalInformation = formState.personalInformation.basicPersonalInformation;
  const organisationSpecificInformation =
    formState.organisationalInformation.organisationSpecificInformation;

  useEffect(() => {
    if (employeeData) {
      setShowUnsavedChangesWarning(!_.isEqual(employeeData.form_data, formState));
    }
  }, [employeeData, formState]);

  //Reset show invalid fields
  useEffect(() => {
    setShowInvalidFieldsSave(false);
    setShowInvalidFieldsStartBot(false);
  }, []);

  useEffect(() => {
    if (employeeData) {
      // get latest botRun in botRuns
      const latestBotRun = employeeData.botRuns!.sort((a, b) => {
        return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
      })[0];
      if (latestBotRun && userIsAdmin) {
        setNotificationText(latestBotRun.message);
        setNotificationType(latestBotRun.result);
      }
    }
  }, [employeeData]);

  const renderFormPart = (section: string) => {
    switch (section) {
      case "personalInformation":
        return <PersonalInformation />;
      case "organisation":
        return <OrganisationalInformation />;
      default:
        return <div>?</div>;
    }
  };

  const saveEmployeeData = async () => {
    if (validToSaveData(formState)) {
      if (employeeData && id !== "new") {
        //creates a deep copy
        const updateEmployeeRequest = JSON.parse(JSON.stringify(employeeData));
        delete updateEmployeeRequest.id;
        updateEmployeeRequest.form_data = formState;
        const status = await putEmployeeData(
          JSON.stringify(updateEmployeeRequest),
          employeeData.id!
        );
        if (status !== 200) {
          //TODO: Show error
        }
      } else if (id === "new") {
        const result = await postEmployeeData({
          employer: user,
          employeeNumber: organisationSpecificInformation.personalNumber,
          firstName: basicPersonalInformation.firstName,
          lastName: basicPersonalInformation.lastName,
          sex: basicPersonalInformation.sex,
          documents: [],
          hireDate: organisationSpecificInformation.startDate,
          status: "draft",
          form_data: formState,
        });
        navigate(`/processes/${result.employee_id}`);
      }
      setShowUnsavedChangesWarning(false);
    } else {
      setShowInvalidFieldsSave(true);
      setShowInvalidFieldsStartBot(false);
      alert(
        "Bitte füllen Sie alle Pflichtfelder aus. Checken Sie bitte sowohl den Reiter 'Persönliche Informationen' als auch den Reiter 'Organisation'."
      );
    }
  };

  const startBot = async () => {
    if (validToStartBot(formState) && validToSaveData(formState)) {
      if (botEnvironmentAvailable) {
        await saveEmployeeData();
        const res = await addQueueItem("queue", id!);
        setNotificationText("Datensatz ist nun in der Warteschlange");
        setNotificationType("info");
        //scroll to top
        setShowModal(true);
      } else {
        setShowBotVideo(true);
      }
    } else {
      setShowInvalidFieldsSave(true);
      setShowInvalidFieldsStartBot(true);
      setNotificationText("Bitte füllen Sie alle Pflichtfelder aus");
      setNotificationType("warning");
    }

    window.scrollTo(0, 0);
  };

  const changeEmployeeStatus = async (
    notificationText: string,
    notificationType: NotificationType,
    status: string
  ) => {
    if (id !== "new" && validToSaveData(formState)) {
      await saveEmployeeData();
      await putEmployeeData(JSON.stringify({ status }), id!);
      setNotificationText(notificationText);
      setNotificationType(notificationType);
      setShowModal(true);
    } else {
      setShowInvalidFieldsStartBot(true);
      setNotificationText("Bitte speichern Sie den Datensatz zuerst");
      setNotificationType("warning");
    }
    window.scrollTo(0, 0);
  };

  return (
    <>
      {isLoading ? (
        <div className="w-full flex justify-center">
          <div className="w-[928px] mt-32 flex flex-col gap-5">
            <SkeletonText />
            <SkeletonText />
            <SkeletonText />
            <SkeletonText />
          </div>
        </div>
      ) : (
        <>
          <WarningMessage
            showDialog={!!showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
          />
          {id && id !== "new" && (
            <Chat
              show={showChat}
              // TODO: actually update message object
              onUpdateChatMessages={(messages) => {
                employeeData && (employeeData.chat_messages = messages);
                setUnreadMessages(messages.filter((m) => m.sender !== user && !m.read).length);
              }}
              onClose={() => setShowChat(false)}
              // onShow={() => setUnreadMessages(0)}
              userId={id}
            />
          )}
          <div className="w-full flex justify-center">
            <div className="w-[928px]">
              <Breadcrumb className="mt-10">
                <BreadcrumbItem onClick={() => navigate(`${routePrefix}/processes`)}>
                  Übersicht
                </BreadcrumbItem>
                <BreadcrumbItem>
                  {employeeData ? employeeData.employeeNumber : "XXXXXXXX"}
                </BreadcrumbItem>
                <span className="">Datenbereitstellung Eingabemaske</span>
              </Breadcrumb>
              <div className="flex justify-end">
                <Toggle
                  labelText="Bot Umgebung vorhanden?"
                  labelA="Nein"
                  labelB="Ja"
                  id="botUmgebung"
                  size="sm"
                  onToggle={(e) => setBotEnvironmentAvailable(e)}
                />
              </div>
              <div className="flex flex-row justify-between items-end pb-6">
                <div>
                  <h4 className="text">Personalstammdaten</h4>
                  <p className="text-sm">
                    Bitte geben Sie die Personalstammdaten für den neuen Mitarbeitenden an
                  </p>
                </div>
                <div className="flex gap-2">
                  <div className="relative">
                    {unreadMessages !== 0 && (
                      <NotificationBubble text={unreadMessages?.toString()} />
                    )}
                    <IconButton size="sm" kind="ghost" label="Chat" disabled={id === "new"}>
                      <ChatLaunch onClick={() => setShowChat(true)} />
                    </IconButton>
                  </div>

                  {userIsAdmin && (
                    <>
                      <IconButton
                        size="sm"
                        kind="ghost"
                        label="Download Document"
                        disabled={!employeeData?.documents[0]}
                        onClick={() => {
                          downloadDocument(id!, employeeData!.documents[0]!.name!);
                        }}
                      >
                        <DocumentDownload />
                      </IconButton>
                      <IconButton size="sm" kind="ghost" label="Start Bot" onClick={startBot}>
                        <PlayFilledAlt />
                      </IconButton>
                    </>
                  )}
                  <IconButton
                    className="bg-primary"
                    label="Save"
                    size="sm"
                    onClick={() => saveEmployeeData()}
                  >
                    <Save />
                  </IconButton>
                </div>
              </div>
              {/* Notifications */}
              {notificationText && (
                <InlineNotification
                  onClose={() => setNotificationText("")}
                  kind={notificationType}
                  className="bg-peras-blue-200 text-peras-blue-400 max-w-none text-sm"
                >
                  {notificationText}
                </InlineNotification>
              )}
              <ContentSwitcher
                className="mt-8"
                onChange={(e) => {
                  setSection(e.name);
                }}
              >
                <Switch name="personalInformation" text="Persönliche Informationen" />
                <Switch name="organisation" text="Organisation">
                  Second section
                </Switch>
              </ContentSwitcher>
              {renderFormPart(section)}

              {userIsAdmin ? (
                <div className="flex justify-between gap-2 mb-32">
                  <Button
                    className="w-[90px]"
                    kind="danger--tertiary"
                    onClick={() => {
                      if (window.confirm("Wollen Sie den Datensatz wirklich löschen?")) {
                        deleteEmployee(id!).then(() => {
                          navigate(`${routePrefix}/processes`);
                        });
                      }
                    }}
                  >
                    Löschen
                  </Button>
                  <div className="flex gap-2">
                    <Button
                      className="w-[160px]"
                      kind="secondary"
                      size="sm"
                      onClick={() => {
                        changeEmployeeStatus(
                          "Prozess wurde als unvollständig markiert",
                          "warning",
                          "incomplete"
                        );
                      }}
                    >
                      <div className="flex flex-row gap-8">
                        unvollständig
                        <CloseOutline />
                      </div>
                    </Button>
                    <Button
                      className="w-[160px]"
                      size="sm"
                      kind="tertiary"
                      onClick={() => {
                        changeEmployeeStatus(
                          "Prozess erfolgreich abgeschlossen",
                          "success",
                          "finished"
                        );
                      }}
                    >
                      <div className="flex flex-row gap-8">
                        Abschließen
                        <CheckmarkOutline />
                      </div>
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="flex justify-end gap-2 mb-32">
                  <Button
                    className="w-[220px] pr-2"
                    size="sm"
                    kind="tertiary"
                    onClick={() => {
                      changeEmployeeStatus("Datensatz wird überprüft", "info", "checking");
                    }}
                  >
                    <div className="flex flex-row gap-8 font-bold">
                      Datensatz einreichen
                      <CheckmarkOutline />
                    </div>
                  </Button>
                </div>
              )}
              <Modal show={showModal}>
                <div className="flex h-[250px] w-[500px] bg-white relative rounded-[3px]">
                  <CloseOutline
                    className="absolute top-4 right-4 cursor-pointer"
                    onClick={() => setShowModal(false)}
                  />
                  <div className="w-full mx-20 my-12">
                    <h2 className="text-xl mb-4">Status erfolgreich geändert</h2>
                    <p className="text-sm mb-4 w-[380px]">{notificationText}</p>

                    <Button
                      className="float-right bg-primary hover:bg-peras-blue-400"
                      onClick={() => navigate(`${routePrefix}/processes`)}
                    >
                      Zur Übersicht
                    </Button>
                  </div>
                </div>
              </Modal>
              <CarbonModal
                open={showBotVideo && !botEnvironmentAvailable}
                passiveModal
                modalHeading="Unser RPA-Bot wird die Daten im SAP-System eintragen:"
                onRequestClose={() => setShowBotVideo(false)}
              >
                <BotVideo />
              </CarbonModal>
            </div>
          </div>
        </>
      )}
    </>
  );
}
