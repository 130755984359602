import { useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Theme } from "@carbon/react";
import LoginPage from "./pages/LoginPage/LoginPage";
import { MvpRoute } from "./types/MvpRoute";
import { RecoilRoot } from "recoil";
import Layout from "./components/Layout/Layout";
import CustomerDashboard from "./pages/CustomerDashboard/CustomerDashboard";
import UploadPage from "./pages/UploadPage/UploadPage";
import AdminDashboard from "./pages/AdminDashboard/AdminDashboard";
import { UserContext } from "./components/UserContext/UserContext";
import MasterDataInputPage from "./pages/PersonalMasterDataInputPage/MasterDataInputPage";

function App() {
  const [currentTheme, setCurrentTheme] = useState("white");
  const [activePage, setActivePage] = useState(0);
  let routeId = 0;

  const routes: MvpRoute[] = [
    { element: LoginPage, path: "/", label: "login-customer", isStartPage: true, id: routeId++ },
    { element: LoginPage, path: "/admin", label: "login-admin", isStartPage: true, id: routeId++ },
    {
      element: CustomerDashboard,
      path: "/processes",
      label: "Vorgänge",
      isStartPage: false,
      id: routeId++,
    },
    {
      element: AdminDashboard,
      path: "/admin/processes",
      label: "Admin-Vorgänge",
      isStartPage: false,
      id: routeId++,
    },
    {
      element: MasterDataInputPage,
      path: "/processes/:id",
      label: "",
      isStartPage: false,
      id: routeId++,
    },
    {      
      element: MasterDataInputPage,
      path: "/admin/processes/:id",
      label: "",
      isStartPage: false,
      id: routeId++,},
    {
      element: UploadPage,
      path: "/processes/upload",
      label: "",
      isStartPage: false,
      id: routeId++,
    },
  ];

  return (
    <RecoilRoot>
      <BrowserRouter>
        <Theme className="themeWrapper" theme={currentTheme}>
          <UserContext>
            <Layout
              mvpRoutes={routes}
              appPrefix="Peras"
              appTitle="Hoop"
              activePage={activePage}
              setActivePage={setActivePage}
            >
              <Routes>
                {routes.map((route, index) => {
                  return <Route element={<route.element />} path={route.path} key={index} />;
                })}
              </Routes>
            </Layout>
          </UserContext>
        </Theme>
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;
