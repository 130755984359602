import { useEffect, useState } from "react";
import axios from "axios";

import { useNavigate } from "react-router-dom";

import {
  Button,
  FormItem,
  FileUploaderDropContainer,
  FileUploaderItem,
  
} from "@carbon/react";
import { ArrowRight, CloseOutline } from "@carbon/icons-react";
import wait from "../../utils/wait";
import Modal from "../../components/Modal/Modal";
import { useUser } from "../../atoms/useUser";

export default function UploadPage() {
  const [fileInvalid, setFileInvalid] = useState(true);
  const [file, setFile] = useState<File>();
  const [fileStatus, setFileStatus] = useState("edit");
  const [showModal, setShowModal] = useState(false);

  
  const [user, setUser] = useUser();
  const doctype = "Personalstammblatt";

  const navigate = useNavigate();

  async function upload() {
    const url = `/api/employees/from_document/?employer=${user}&doc_type=${doctype}`;
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      try {
        setFileStatus("uploading");
        const response = await axios.post(url, formData, config);
        setFileStatus("complete");
        await wait(500);
        setShowModal(true);
      } catch (error) {
        setFileInvalid(true);
        setFileStatus("edit");
      }
    }
  }

  const handleAddFile = (e) => {
    e.preventDefault();
    setFileInvalid(false);

    if (e.type === "change") {
      setFile(e.target.files[0]);
    } else {
      setFile(e.dataTransfer.files[0]);
    }
  };

  return (
    <>
      <Modal show={showModal}>
        <div className="flex h-[250px] w-[500px] bg-white relative rounded-[3px]">
          <CloseOutline
            className="absolute top-4 right-4 cursor-pointer"
            onClick={() => setShowModal(false)}
          />
          <div className="w-full mx-20 my-12">
            <h2 className="text-xl mb-4">Dokument erfolgreich hochgeladen</h2>
            <p className="text-sm mb-4 w-[380px]">
              Das Personalstammblatt wird automatisiert ausgelesen und anschließend von den Sachbearbeiter:innen überprüft.
              Bei Fragen kommen wir auf Sie zurück.
            </p>

            <Button className="float-right bg-primary hover:bg-peras-blue-400" onClick={() => navigate('/processes')}>Zur Übersicht</Button>
          </div>
        </div>
      </Modal>
      <div className="flex justify-center">
        <div className="w-[928px]">
          <h2 className="mt-14 mb-14 text-xl">
            Bitte laden Sie das ausgefüllte Personalstammblatt hoch
          </h2>

          <FormItem>
            <p className="cds--file--label">Dokument hochladen</p>
            <p className="cds--label-description">
              Maximale Dateigröße ist 2MB. Erlaubtes Format ist .pdf
            </p>
            <FileUploaderDropContainer
              accept={["application/pdf"]}
              labelText="Ziehen Sie die Dateien hierher oder klicken Sie zum Hochladen"
              multiple={false}
              onAddFiles={handleAddFile}
            />
            <div className="cds--file-container" style={{ width: "100%" }}>
              {file ? (
                <FileUploaderItem
                  errorBody="There was an error uploading the file. Please try again."
                  errorSubject="Upload Error"
                  iconDescription="Clear file"
                  name={file.name}
                  invalid={fileInvalid}
                  onDelete={function noRefCheck() {
                    setFileInvalid(true);
                    setFile(undefined);
                  }}
                  size="md"
                  status={fileStatus}
                />
              ) : null}
            </div>
            <Button className="bg-primary hover:bg-peras-blue-400 mt-24 pr-6" disabled={fileInvalid} onClick={upload}>Weiter
            <ArrowRight size={20} className="text-primary ml-10" /></Button>
          </FormItem>
        </div>
      </div>
    </>
  );
}
